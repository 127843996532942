import pvid from '../assets/vid/Edit_Horizontal.mp4';

export const Hero = () => {
    return (
        <div id='hero'>
            <video id='deskVid' autoPlay loop muted playsInline>
                <source src={pvid} type='video/mp4' />
            </video>
            <h1 id='cName'>Physical Power Club</h1>
            <video id='mobileVid' autoPlay loop muted playsInline>
                <source src={pvid} type='video/mp4' />
            </video>
        </div>
    );
};