import SimpleImageSlider from "react-simple-image-slider";

import foto1 from "../assets/img/Foto_1.jpg";
import foto2 from "../assets/img/Foto_2.jpg";
import foto3 from "../assets/img/Foto_3.jpg";
import foto4 from "../assets/img/Foto_4.jpg";
import foto7 from "../assets/img/Foto_7.jpg";
import foto8 from "../assets/img/Foto_8.jpg";
import foto10 from "../assets/img/Foto_10.jpg";
import foto11 from "../assets/img/Foto_11.jpg";

export const Gallery = () => {

    const images = [
        { url: foto1 },
        { url: foto2 },
        { url: foto3 },
        { url: foto4 },
        { url: foto7 },
        { url: foto8 },
        { url: foto10 },
        { url: foto11 },
    ];

    return <div id="gallerij">
        <SimpleImageSlider
            id="gallerij"
            height="100vh"
            width="100vw"
            images={images}
            showBullets={true}
            showNavs={true}
            autoPlay={true}
            loop={true}
            slideDuration={2}
        />
    </div>

};
