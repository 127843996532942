import React from 'react';
import ReactDOM from 'react-dom/client';
import {Navbar} from './components/Navbar';
import {ThemeProvider, createTheme} from '@mui/material';
import {Hero} from "./components/Hero";
import {About} from "./components/About";
import {Offerings} from "./components/Offerings";
import {Gallery} from "./components/Gallery";
import {Info} from "./components/Info";
import {Footer} from "./components/Footer";
import './index.css';


const theme = createTheme({
    palette: {
        primary: {
            main: '#19494D',
            light: "#1f575c"
        },
        secondary: {
            main: '#E8E7E8',
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Navbar/>
            <Hero/>
            <About/>
            <Offerings/>
            <Gallery/>
            <Info/>
            <Footer/>
        </ThemeProvider>
    </React.StrictMode>
);
