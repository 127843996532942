export const Offerings = () => {
    return (
        <div id='aanbod'>
            <div id='shape'></div>
            <div id='aText'>
                <h2>Aanbod</h2>
                <p>
                    In de Physical betaalt u zich niet blauw aan het abonnement. Er is
                    keuze tussen een jaarabonnement of een tien- beurtenkaart.
                    <br/>
                    <br/>
                    Een abonnement geeft bovendien recht op een vergoeding bij de
                    mutualiteit op vertoon van het inschrijvingsbewijs van onze club.
                    <br/>
                    <br/>
                    *Eigen dranken zijn niet toegelaten!
                </p>
            </div>
            <div id='cards'>
                <div className='card'>
          <span className='price'>
            €99<span className='unit'>/jaar</span>
          </span>
                    <h3 className='cardTitle'>Jaarabonnement</h3>
                    <p className='cardDesc'>
                        Zeker van je keuze? Dan is het klassieke jaarabonnement voor jou.
                        Makkelijk, voordelig en ongelimiteerde toegang.{' '}
                    </p>
                    <ul>
                        <li>Gratis coaching</li>
                        <li>Geen domicilliëring</li>
                        <li>Ongelimiteerde Toegang</li>
                    </ul>
                </div>
                <div className='card'>
                    {' '}
                    <span className='price'>
            €60<span className='unit'>/kaart</span>
          </span>
                    <h3 className='cardTitle'>Beurtenkaart</h3>
                    <p className='cardDesc'>
                        Eerst even uitproberen? Met deze beurtenkaart kan je tot 10
                        trainingen uitproberen.
                    </p>
                    <ul>
                        <li>Onbeperkt geldig</li>
                        <li>Eenmalig betalen</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};