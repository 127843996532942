import React, {useState, useMemo} from 'react';
import {
    Box,
    IconButton,
    Link,
    SwipeableDrawer,
    Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

export const Navbar = () => {
    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const [navItems] = useState([
        {label: 'Over ons', url: '#overOns'},
        {label: 'Aanbod', url: '#aanbod'},
        {label: 'Gallerij', url: '#gallerij'},
        {label: 'Info', url: '#info'}
    ]);

    const toggleMobileNav = (open) => {
        setMobileNavOpen(open);
    };

    const getDesktopNavItems = () => {
        return (<>
            {navItems.map((item, idx) => (
                <Link key={idx} href={item.url} color="inherit" underline="none">
                    {item.label}
                </Link>
            ))}
        </>);
    }

    const getMobileNavItems = () => {
        return (<>
            {navItems.map((item, idx) => {
                return (
                    <Link
                        key={idx}
                        href={item.url}
                        onClick={() => toggleMobileNav(false)}
                        sx={{
                            color: 'secondary.main',
                            textDecoration: 'none',
                            height: '25%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            '&:hover': {backgroundColor: 'primary.light'},
                        }}
                    >
                        {item.label}
                    </Link>
                );
            })}
        </>);

    }

    const LeftSide = () => {
        return (
            <Box>
                <Typography
                    variant='h3'
                    sx={{
                        display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'none',
                            lg: 'block',
                            xl: 'block',
                        },
                    }}
                >
                    Physical Power Club
                </Typography>

                <Box
                    component='img'
                    src={require('../assets/physical_logo_wit_zonder_achtergrond_navbar.png')}
                    alt='logo'
                    sx={{
                        height: '65px',
                        width: '65px',
                        display: {
                            xs: 'block',
                            sm: 'block',
                            md: 'block',
                            lg: 'none',
                            xl: 'none',
                        },
                    }}
                />
            </Box>
        );
    };

    const RightSide = () => {
        return (
            <Box
                sx={{
                    width: '60%',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <DesktopNav/>
                <MobileNavButton/>
            </Box>
        );
    };

    const DesktopNav = () => {
        return (
            <Box
                sx={{
                    width: '100%',
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'block',
                        lg: 'block',
                        xl: 'block',
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                    }}
                >
                    {getDesktopNavItems()}
                </Box>
            </Box>
        );
    };

    const MobileNavButton = () => {
        return (
            <IconButton
                sx={{
                    position: 'absolute',
                    top: '20px',
                    right: '25px',
                    size: 'large',
                    transform: 'scale(2)',
                    color: 'secondary.main',
                    display: {
                        xs: 'block',
                        sm: 'block',
                        md: 'none',
                        lg: 'none',
                        xl: 'none',
                    },
                }}
                onClick={() => toggleMobileNav(true)}
            >
                <MenuIcon/>
            </IconButton>
        );
    };

    const MobileNav = useMemo(() => {
        return (
            <>
                <SwipeableDrawer
                    anchor={'left'}
                    open={mobileNavOpen}
                    onOpen={() => toggleMobileNav(true)}
                    onClose={() => toggleMobileNav(false)}
                >
                    <Box
                        sx={{
                            width: '70vw',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'primary.main',
                        }}
                    >
                        <Box
                            sx={{
                                height: '80%',
                                width: '100%',
                                margin: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Box
                                component='img'
                                src={require('../assets/physical_logo_wit_zonder_achtergrond_navbar.png')}
                                alt='logo'
                                sx={{
                                    height: '180px',
                                    width: '180px',
                                }}
                            />
                            <Box
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    paddingTop: '10%',
                                    margin: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                {getMobileNavItems()}
                            </Box>
                        </Box>
                    </Box>
                </SwipeableDrawer>
            </>
        );
    }, [mobileNavOpen, getMobileNavItems]);

    return (
        <>
            <Box
                sx={{
                    position: 'sticky',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '75px',
                    paddingLeft: '40px',
                    paddingRight: '40px',
                    backgroundColor: 'primary.main',
                    color: 'secondary.main',
                }}
            >
                <LeftSide/>
                <RightSide/>
                {MobileNav}
            </Box>
        </>
    );
};
