import facebookIcon from "../assets/facebook_icon_wit.png"
import instagramIcon from "../assets/instagram_icon_wit.png"

export const Footer = () => {
    return (
        <div id='footer'>
            <h2>Physical Merchtem</h2>

            <div className="items">

                <div className="footer-items">
                    <h3>Contacteer ons</h3>
                    <p>
                        &#9742;<a href="tel:+3252372390"><strong> +32 52 37 23 90</strong></a> <br/>
                    </p>
                </div>

                <p className="footer-items">
                    <strong>August de Boeckstraat 29<br/>1785 Merchtem</strong>
                </p>

                <div className="footer-items">
                    <h3>Volg onze socials voor de nieuwste updates!</h3>
                    <div className="social-links">
                        <a href="https://nl-be.facebook.com/physicalmerchtem/" target="_blank" rel="noreferrer"><img
                            src={facebookIcon} alt="facebook_link"/></a>
                        <a href="https://www.instagram.com/physical_merchtem/" target="_blank" rel="noreferrer"> <img
                            src={instagramIcon} alt="instagram_link"/></a>
                    </div>
                </div>
            </div>
        </div>
    );
};


