export const Info = () => {
    return (
        <div id='info'>
            <h2>Openingsuren</h2>
            <div id='infoFlex'>
                <div id='uren'>
                    <ul>
                        <li>
                            <strong>Maandag</strong>09u30 - 12u00 en 15u30 - 22u00
                        </li>
                        <li>
                            <strong>Dinsdag</strong>09u30 - 12u00 en 15u30 - 22u00
                        </li>
                        <li>
                            <strong>Woensdag</strong>15u30 - 22u00
                        </li>
                        <li>
                            <strong>Donderdag</strong>09u30 - 12u00 en 15u30 - 22u00
                        </li>
                        <li>
                            <strong>Vrijdag</strong>09u30 - 12u00 en 15u30 - 22u00
                        </li>
                        <li>
                            <strong>Zaterdag</strong>13u00 - 17u00
                        </li>
                        <li>
                            <strong>Zondag</strong>GESLOTEN
                        </li>
                    </ul>
                </div>
                <div id='locatie'>
                    <iframe
                        title="google_maps_indicator"
                        src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10052.116729375923!2d4.2276227!3d50.9601647!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3eacb1067634d%3A0x78cc54bce17c349!2sPhysical%20power%20club!5e0!3m2!1snl!2sbe!4v1681408207082!5m2!1snl!2sbe'
                        loading='lazy'
                    ></iframe>
                </div>
            </div>
        </div>
    );
};