import rik from '../assets/img/Foto_5_small.jpg';

export const About = () => {
    return (
        <div id='overOns'>
            <div id='intro'>
                <div id='oText'>
                    <h2>Over ons</h2>
                    <p>
                        Wie fitness zegt, denkt Physical Power Club Merchtem. ‘De Physical’
                        is een sportclub met een rijke geschiedenis. De club werd opgericht
                        in 1982 door topatleet Hugo De Grauwe. Al snel groeide de club uit
                        tot een befaamde powerliftingclub die tot op de dag van vandaag zijn
                        naam waarmaakt in Merchtem en omstreken. Met de hulp van boegbeeld
                        Hugo groeiden vele mensen uit tot topatleten.
                        <br />
                        <br />
                        Zoon Rik De Grauwe trad in de voetsporen van zijn vader en bouwde in
                        no time een topcarrière uit in het powerliften. Hij nam recent de
                        fakkel over van zijn vader als uitbater van de club. De Physical is
                        een club voor iedereen. De goed uitgeruste club biedt de
                        mogelijkheid om zich naast het powerliften te verdiepen in
                        gewichtheffen, bodybuilding, conditietraining…
                        <br />
                        <br />
                        Een uitgebreide keuze aan toestellen zorgt ervoor dat iedereen er
                        naar hartenlust kan trainen op zijn of haar tempo. De club is
                        voorzien van gescheiden kleedkamers en douches. Na een intensieve
                        training kan je bovendien altijd terecht in de sfeervolle bar voor
                        een verfrissend, deugddoend en welverdiend drankje of een gezellig
                        spelletje darts.
                    </p>
                </div>
                <div id='oFoto'>
                    <img src={rik} alt='Rik De Grauwe' />
                </div>
            </div>
            <div id='slogan'>
                <span>Join the club, join the family, join de Physical!</span>
            </div>
        </div>
    );
};